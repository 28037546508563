

// generated code

export interface AppInfo {
   packageVersion: string,
   gitShortHash: string,
   gitHash: string,
   commitDateShort: string,
   commitDateTimeShort: string
   fullVersion: string
}

const appInfo : AppInfo = {
  packageVersion : '0.1.0',
  gitShortHash: '2177943',
  gitHash: '21779433e43b35d7d6205b59f3dc41cfcef02d14',
  commitDateShort: '2024-11-27',
  commitDateTimeShort: '2024-11-27T17:21:24',
  fullVersion: '0.1.0-2024-11-27.2177943'
};

export default appInfo;

